
import ProgressBar from '@/components/ProgressBar.vue'
import ExcursionButtons from '@/components/excursions/ExcursionButtons.vue'

import { urlencode } from '@/utils/index'

export default {
    data: function() {
        return {
            mounted: false,
            subscriptions: 0,
            all_subscriptions: 0,
        }
    },
    props: [
        'scope',
        'excursion',
    ],
    components: {
        ProgressBar,
        ExcursionButtons,
    },
    methods: {
        showInfo: function() {
            if (this.excursion.type === 'reis') {

                // TODO: containment
                let scope = (this.excursion.scope === 'europa') ?
                    'buitenlandse-reizen': this.excursion.scope

                this.$router.push(
                    //'/activiteiten/' + this.excursion.scope + '/' + urlencode(this.excursion.name)
                    '/activiteiten/' + scope + '/' + urlencode(this.excursion.name)
                )
            } else {
                this.$parent.showActivityInfo(this.excursion)
            }
        },
        showExcursionSubscriptionsModal(excursion) {
            this.$parent.showExcursionSubscriptionsModal(excursion)
        },
        showExcursionAdminModal(excursion) {
            this.$parent.showExcursionAdminModal(excursion)
        }
    },
    computed: {
        isKeuzedeel: function() {
            return this.excursion.type === 'keuzedeel'
        },
        getPrice: function() {
            if (!this.mounted) return
            let costs = this.excursion.costs_students
            if (typeof(costs) === 'number' && costs > 0) {
                return '€' + String(this.excursion.costs_students.toFixed(2)).replace('.', ',')
            }
            return 'Geen'
        },
        getActiveClass() {
            if (!this.mounted) return
            if (!this.excursion.active) return 'inactive-excursion'
            return ''
        }
    },
    mounted() {
        this.mounted = true
        if ('subscriptions' in this.excursion) {
            this.subscriptions = this.excursion.subscriptions.filter(sub => sub.preference == 1).length
            this.all_subscriptions = this.excursion.subscriptions.length
        }
    },
    watch: {
        'excursion.subscriptions': function(newValue) {
            if (!(typeof(newValue) === 'object')) return
            this.subscriptions = newValue.filter(sub => sub.preference == 1).length
            this.all_subscriptions = newValue.length
        },
    }
}
