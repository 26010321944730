
import { mapGetters } from 'vuex'
import ProgressBar from '@/components/ProgressBar.vue'

export default {
    name: 'InfoModal',
    data() {
        return {
            subscriptions: {Number, default: 0}
        }
    },
    props: {
        activity: Object,
    },
    components: {
        ProgressBar
    },
    methods: {
        resetModal() {
            this.excursion = {}
        },
        setSubscriptions(activity) {
            if (!(typeof(activity.subscriptions) === 'object')) return
            this.subscriptions = activity.subscriptions.filter(sub => sub.preference == 1).length
        },
    },
    computed: {
        ...mapGetters(['isAdmin']),
        getSubscriptions: function() {
            var subs = this.activity.subscriptions
            if (!subs) return 0
            return subs
        },
        getPrice() {
            return String(this.activity.costs_students.toFixed(2)).replace('.', ',')
        },
        getScaldaPrice() {
            return String(this.activity.costs_scalda.toFixed(2)).replace('.', ',')
        },
    },
    mounted() {
        this.setSubscriptions(this.activity)
    },
    watch: {
        activity: function(newValue) {
            this.setSubscriptions(newValue)
        }
    }
}
