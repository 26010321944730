
// @ is an alias to /src
import CKEditor from '@/components/CKEditor.vue'

import ExcursionCard from '@/components/excursions/ExcursionCard.vue'

import ExcursionAdminModal from '@/components/excursions/ExcursionAdminModal.vue'
import ActivityInfo from '@/components/excursions/ActivityInfo.vue'
import ExcursionSubscriptions from '@/components/subscriptions/ExcursionSubscriptions.vue'

import { SET_CONTENT_REQUEST } from '@/store/actions/website_content.ts'
import { UPDATE_EXCURSION_REQUEST, EXCURSION_REQUEST } from '@/store/actions/excursion.ts'

import { mapGetters, mapState } from 'vuex'

//import Banner from '@/components/Banner.vue'

export default {
    name: 'Excursies',
    data: function() {
        return {
            scope: null,

            refresh_id: null,

            excursion: {},
        }
    },
    components: {
        CKEditor,
        ExcursionCard,
        ExcursionAdminModal,
        //Banner,
        ActivityInfo,
        ExcursionSubscriptions,
    },
    methods: {
        showActivityInfo: function(excursion) {
            this.excursion = excursion
            this.$bvModal.show('acivity-info-modal')
        },
        showExcursionAdminModal: function(excursion) {
            this.excursion = excursion
            this.$bvModal.show('excursion-admin-modal')
        },
        showExcursionSubscriptionsModal: function(excursion) {
            this.excursion = excursion
            this.$bvModal.show('excursion-subscriptions-modal')
        },
        get_content: function(content_section) {
            let store = this.$store.state.website_content.content
            if (!(content_section in store)) return ''
            return store[content_section]
        },
        set_content: function(name, new_content) {
            this.$store.dispatch(SET_CONTENT_REQUEST, {name: name, content: new_content}).then()
        },
        set_excursion_teaser: function(id, new_content) {
            this.$store.dispatch(UPDATE_EXCURSION_REQUEST, { id, teaser: new_content }).then()
        },
        scrollToExcursionsContainer() {
            let el = this.$refs['excursions-container']
            window.scrollTo(0, el.offsetTop)
        },
    },
    created: function() {
        // TODO: Containment (used to be: this.scope = this.$route.params.scope)
        this.scope = (this.$route.params.scope === 'buitenlandse-reizen') ?
            'europa' : this.$route.params.scope
    },
    computed: {
        ...mapState({
            excursions: (state: any) => state.excursion.excursions,
            role: (state: any) => state.auth.user.role,
        }),
        ...mapGetters([
            'isAdmin'
        ]),
        getKeuzedelen: function() {
            let keuzedelen = this.$store.state.excursion.excursions
                .filter(ex => ex.type === 'keuzedeel' && ex.scope === this.scope)
            if (this.role !== 'admin') return keuzedelen.filter(ex => ex.active)
            return keuzedelen
        },
        getReizen: function() {
            let reizen = this.$store.state.excursion.excursions
                .filter(ex => ex.type === 'reis' && ex.scope === this.scope)
            if (this.role !== 'admin') return reizen.filter(ex => ex.active)
            return reizen
        },
        getActivities: function() {
            let activities = this.$store.state.excursion.excursions
                .filter(ex => ex.type === 'activiteit' && ex.scope === this.scope)
            if (this.role !== 'admin') return activities.filter(ex => ex.active)
            return activities
        },
    },
    mounted: function () {
        //this.scrollToExcursionsContainer()
    },
    destroyed() {
        clearInterval(this.refresh_id)
    },
    watch: {
        $route (to, from) {
            // TODO: Containment (used to be: this.scope = this.$route.params.scope)
            this.scope = (this.$route.params.scope === 'buitenlandse-reizen') ?
                'europa' : this.$route.params.scope
        }
    }
}
